<template>
    <div class="vx-col md:w-full sm:w-auto">
        <div class="vx-row md:w-full">
            <vx-tooltip class="f-r" text="Phê duyệt" position="top">
                <feather-icon icon="CheckIcon" class="action-success icon-btn"
                              svgClasses="h-5 w-5 hover:text-primary cursor-pointer"
                              @click="confirmAcceptLeave"/>
            </vx-tooltip>

            <vx-tooltip class="f-r" text="Từ chối" position="top">
                <feather-icon icon="XIcon" class="action-err icon-btn"
                              svgClasses="h-5 w-5 hover:text-danger cursor-pointer"
                              @click="confirmRejectLeave"/>
            </vx-tooltip>

            <vx-tooltip class="f-r" text="Chỉnh sửa" position="top">
                <feather-icon icon="EditIcon" class="action-success icon-btn"
                              svgClasses="h-5 w-5 hover:text-primary cursor-pointer"
                              @click="editRecord"/>
            </vx-tooltip>
        </div>
    </div>
</template>

<script>
export default {
    name: 'CellRendererActions',
    methods: {
        editRecord() {
            this.$router.push(`/user/edit-leave?branch_id=${this.params.data.organizationBranchId}&id=${this.params.data.id}`).catch(() => {
            })
        },
        confirmAcceptLeave() {
            this.$vs.dialog({
                type: 'confirm',
                color: 'danger',
                title: `Xác nhận duyệt`,
                text: `Bạn có chắc là muốn duyệt đơn xin nghỉ của nhân viên "${this.params.data.fullName}" không ? `,
                accept: this.acceptLeave,
                acceptText: "Xác nhận",
                cancelText: "Huỷ"
            })
        },
        acceptLeave() {
            this.$vs.loading();
            this.$crm.post('/leave/update/' + this.params.data.id, {status: 1}).then(() => {
                this.$vs.loading.close();
                this.showUpdateSuccess();
                this.$store.commit('UPDATE_LEAVE', true);
            }).catch((err) => {
                this.$vs.loading.close();
                return this.$vs.notify({
                    text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                });
            });
        },
        confirmRejectLeave() {
            this.$vs.dialog({
                type: 'confirm',
                color: 'danger',
                title: `Xác nhận từ chối`,
                text: `Bạn có chắc là muốn từ chối đơn xin nghỉ của nhân viên "${this.params.data.fullName}" không ? `,
                accept: this.acceptCancelLeave,
                acceptText: "Xác nhận",
                cancelText: "Huỷ"
            })
        },
        acceptCancelLeave() {
            this.$vs.loading();
            this.$crm.post('/leave/update/' + this.params.data.id, {status: 2}).then(() => {
                this.$vs.loading.close();
                this.showUpdateSuccess();
                this.$store.commit('UPDATE_LEAVE', true);
            }).catch((err) => {
                this.$vs.loading.close();
                return this.$vs.notify({
                    text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                });
            });
        },
        showUpdateSuccess() {
            this.$vs.notify({
                color: 'success',
                text: 'Cập nhật thành công',
                iconPack: 'feather',
                icon: 'icon-alert-circle',
            })
        }
    }
}
</script>

<style scoped>


</style>
