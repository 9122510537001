<template>
    <vs-chip class="ag-grid-cell-chip" :color="chipColor(params)">
        <span>{{ organizationTypeString(params) }}</span>
    </vs-chip>
</template>

<script>
export default {
    name: 'CellRendererStatus',
    computed: {
        chipColor() {
            return value => {
                if (value.data.status === 0) {
                    return "warning"
                } else if (value.data.status === 1) {
                    return "success"
                } else if (value.data.status === 2) {
                    return "danger"
                } else if (value.data.status === 3) {
                    return "cancel"
                }
            }
        },
        organizationTypeString() {
            return value => {
                if (value.data.status === 0) {
                    return "Chờ"
                } else if (value.data.status === 2) {
                    return "Từ chối"
                } else if (value.data.status === 1) {
                    return "Đã duyệt"
                } else if (value.data.status == 3) {
                    return "Đã hủy"
                }
            }
        }
    }
}
</script>

<style lang="scss" scpoped>

</style>
